// eslint-disable-next-line no-shadow
export enum slugs {
  home = 'home',
  company = 'company',
  solutions = 'solutions',
  termsOfService = 'terms_of_service',
  cookiesPolicy = 'cookies_policy',
  acceptableUsePolicy = 'acceptable_use_policy',
  GDPR = 'GDPR',
  passwordPolicy = 'password_policy',
  privacyPolicy = 'privacy_policy',
  termsConditions = 'terms_conditions',
  logo = 'logo',
  section = 'section',
  titleSection = 'title_section',
  richtextContent = 'richtext_content',
  banner = 'banner',
  section_ul = 'section_ul',
  button = 'button',
  quotation = 'quotation',
  list = 'list',
  tabs_block = 'tabs_block',
  typography = 'typography',
  device = 'device',
  products_facility = 'products_facility',
  products_asset = 'products_asset',
  products_sync = 'products_sync',
  products_connect = 'products_connect',
  products_forge = 'products_forge',
  coming_soon = 'coming_soon',
  sync = 'sync',
  grid = 'grid'
}
