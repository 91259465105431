/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import { LinkWrapper } from 'components/linkWrapper/LinkWrapper';
import { IMenuItem } from 'models/menuItem';
import { MouseEventHandler, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import styles from './NavSubList.module.scss';

interface NavSubListProps {
  setShownId?: (x: string) => void;
  isMobile?: boolean;
  setIsOpen?: (x: boolean | ((prev: boolean) => boolean)) => void;
  subMenuItem?: IMenuItem;
  navigate: any
}

export const NavSublist = ({ setShownId, isMobile, setIsOpen, subMenuItem, navigate }: NavSubListProps) => {

  const subMenu = subMenuItem?.fields?.subMenu;
  const { show } = useIntercom();

  const menuHandler = (id: string, elem: HTMLLIElement, slug?: string) => {
    const childs = elem.parentElement && [...elem.parentElement.children];

    if (childs?.length) {
      childs.forEach((child) => child.classList.remove(styles.nav__subItem_hovered));
    }
    elem.classList.add(styles.nav__subItem_hovered);
    // setHoveredItemId(id);
    if (slug) {
      if (slug === 'remove_account') {
        const redirectUrl = '/remove-account-request';
        navigate(redirectUrl, { state: new Date().getTime() });
      } else if (slug === 'chat') {
        show();
      }
    }
  };
  const [opacity, setOpacity] = useState(0);
  useEffect(() => {
    setTimeout(() => setOpacity(1), 1);
  }, []);

  return (
    <div className={styles.nav__container} style={{ opacity }}>
      {/* <div className={styles.nav__col}> */}
      {/* <h3 className={styles.nav__subTitle}>{title}</h3> */}
      <ul role="menu" className={styles.nav__subList}>
        {subMenu &&
          subMenu.length &&
          subMenu.map((elem) => (
            <li
              role="menuitem"
              className={styles.nav__subItem}
              key={elem.sys.id}
              // onMouseEnter={(e) => menuHandler(elem.sys.id, e.currentTarget)}
              onClick={(e) => menuHandler(elem.sys.id, e.currentTarget, elem.fields?.slug)}
              onKeyPress={(e) => menuHandler(elem.sys.id, e.currentTarget, elem.fields?.slug)}
              aria-label="sub menu item"
            >
              {['chat', 'remove_account'].includes(elem?.fields?.slug as string) ? (
                <p>{elem.fields.title}</p>
              ) : (
                <LinkWrapper
                  setShownId={setShownId}
                  isLocal={elem.fields?.link?.includes('http')}
                  linkUrl={elem.fields?.link}
                  navigate={navigate}
                >
                  {elem.fields.title}
                </LinkWrapper>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};
