import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Loader } from 'components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { RootState, useAppDispatch } from '../../redux';
import { acceptCookies, fetchCookieEntry } from '../../redux/slices/page';

import styles from './AcceptCookies.module.scss';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

export const AcceptCookies = () => {
  const dispatch = useAppDispatch();
  const { cookie, cookieLoading } = useSelector((state: RootState) => state.page);
  const [showContent, setShowContent] = useState(false);

  const onButtonClick = () => {
    dispatch(acceptCookies(true));
  };
  useEffect(() => {
    dispatch(fetchCookieEntry('cookies_banner'));
  }, []);
  const cookieButton = cookie?.contentModules?.find((i: any) => i?.fields?.slug === 'button');

  if (cookieLoading) return <Loader />;

  return (
    <div className={styles.cookiesBanner} style={{ backgroundColor: cookie?.backgroundColor }}>
      <div
        className={classNames(styles.content, {
          [styles.show]: showContent
        })}
        role="button"
        tabIndex={0}
        onMouseDown={() => setShowContent((prev) => !prev)}
      >
        {documentToReactComponents(cookie?.richTextContent)}
      </div>
      {!isEmpty(cookieButton) && (
        <Button
          title={cookieButton?.fields?.title}
          backgroundColor={cookieButton?.fields?.backgroundColor}
          handler={onButtonClick}
          type={cookieButton?.fields?.backgroundVariant || 'secondary'}
        />
      )}
    </div>
  );
};
