import { useEffect } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '../../redux';
import { getPageFetch } from '../../redux/slices/page';

import {IImage} from "models/Image";
import {IButtonField} from "models/button";

import { Title } from './title/Title';
import { Loader } from 'components';

import { useMobile } from 'utils/hooks';

import { slugs } from 'utils/constants';
import {getImagePath} from "utils/helpers";

export const Soon = () => {
  const dispatch = useAppDispatch();
  const { contentModules, isLoading } = useSelector((state: RootState) => state.page);
  const { isMobile } = useMobile(568);

  const titleSoon = contentModules?.find((i: any) => i?.fields?.slug === 'title');
  const titleSoonBg = titleSoon?.fields?.images?.find((i: IImage) => getImagePath(i, `${isMobile ? 'mobile' : 'desktop'}`))

  useEffect(() => {
    dispatch(getPageFetch(slugs.coming_soon));
  }, []);

  if (isLoading) return <Loader />;

  return (
    <main>
      <Title
        key={get(titleSoon, 'sys.id')}
        content={get(titleSoon, 'fields.content')}
        imagePosition={get(titleSoon, 'fields.imagePosition', 'none')}
        background={titleSoonBg}
        buttons={get(titleSoon, 'fields.contentModules', [])?.filter(
          (i: IButtonField) => i?.fields?.slug === 'button'
        )}
      />
    </main>
  );
};
