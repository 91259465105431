import { put, takeEvery } from 'redux-saga/effects';
import {fetchCookieEntry, getPage, getCookieEntry} from 'redux/slices/page';
import { client } from 'services/contentful.service';

function* getPageWorker({ payload }: any): Generator<any, any, any> {
  try {
    const response = yield client.getEntries({
      content_type: 'page',
      include: 10,
      'fields.slug': payload
    });

    yield put(getPage(response.items[0].fields));
  } catch (e) {
    console.log(e);
  }
}

function* getCookiesWorker({ payload }: any): Generator<any, any, any> {
  try {
    const response = yield client.getEntries({
      content_type: 'block',
      'fields.slug': payload
    });
    yield put(getCookieEntry(response.items[0].fields));
  } catch (e) {
    console.log(e)
  }
}

export function* getPageWatcher() {
  yield takeEvery('page/getPageFetch', getPageWorker);
  yield takeEvery('page/fetchCookieEntry', getCookiesWorker);
}
