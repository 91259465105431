import classNames from 'classnames';
import styles from './Burger.module.scss';

export const Burger = ({
  isOpen,
  setIsOpen
}: {
  isOpen: boolean;
  setIsOpen: (x: boolean | ((prev: boolean) => boolean)) => void;
}) => (
  <button
    className={classNames([styles.burger, isOpen ? styles.burger_active : ''])}
    type="button"
    aria-label="burger"
    onClick={() => setIsOpen((prev) => !prev)}
  />
);
