import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import Modal from 'react-modal';

import { RootState, useAppDispatch } from './redux';

import { Footer, Header, AcceptCookies } from 'components';

import routesConstants from './routing/routes';

import './styles/style.scss';

import { ButtonClose } from './components/buttonClose/ButtonClose';

import { onOpenModal } from 'redux/slices/page';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '11'
  }
};

export const App = () => {
  const { acceptedCookie, isOpenModal } = useSelector((state: RootState) => state.page);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const banner: HTMLElement | null = document.querySelector('[name="intercom-banner-frame"]');
    if (banner) {
      banner.style.opacity = '0';
      banner.style.visibility = 'hidden';
    }
  }, []);

  function closeModal() {
    dispatch(onOpenModal());
  }

  return (
    <>
      <Header// openModal={openModal}
      />
      <Routes>
        <Route path={routesConstants.Home.path} element={<routesConstants.Home.component />} />
        <Route path={routesConstants.Company.path} element={<routesConstants.Company.component />} />
        <Route path={routesConstants.Solutions.path} element={<routesConstants.Solutions.component />} />
        <Route path={routesConstants.ProductsFacility.path} element={<routesConstants.ProductsFacility.component />} />
        <Route path={routesConstants.ProductsAsset.path} element={<routesConstants.ProductsAsset.component />} />
        <Route path={routesConstants.ProductsForge.path} element={<routesConstants.ProductsForge.component />} />
        <Route path={routesConstants.ProductsSync.path} element={<routesConstants.ProductsSync.component />} />
        <Route path={routesConstants.ProductsConnect.path} element={<routesConstants.ProductsConnect.component />} />
        <Route path={routesConstants.ComingSoon.path} element={<routesConstants.ComingSoon.component />} />
        <Route path={routesConstants.TermsOfService.path} element={<routesConstants.TermsOfService.component />} />
        <Route
          path={routesConstants.AcceptableUsePolicy.path}
          element={<routesConstants.AcceptableUsePolicy.component />}
        />
        <Route path={routesConstants.CookiesPolicy.path} element={<routesConstants.CookiesPolicy.component />} />
        <Route path={routesConstants.GDPR.path} element={<routesConstants.GDPR.component />} />
        <Route path={routesConstants.PasswordPolicy.path} element={<routesConstants.PasswordPolicy.component />} />
        <Route path={routesConstants.PrivacyPolicy.path} element={<routesConstants.PrivacyPolicy.component />} />
        <Route path={routesConstants.TermsConditions.path} element={<routesConstants.TermsConditions.component />} />
        <Route path={routesConstants.Sync.path} element={<routesConstants.Sync.component />} />
        <Route path="*" element={<routesConstants.Home.component />} />
      </Routes>
      {!acceptedCookie && <AcceptCookies />}
      <Footer />
      {/* <Modal
        isOpen={!!isOpenModal}
        onRequestClose={() => closeModal()}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div
          className={styles.linkItem}
          data-tf-widget="N1z3n4LD"
          data-tf-iframe-props="title=Service Provider Account Request"
          data-tf-medium="snippet"
          style={{
            minWidth: '300px',
            width: '100%',
            height: '400px'
          }}
        >
          <button
            data-tf-popup="vW2IpASr"
            data-tf-iframe-props="title=Manufacture Account Request"
            data-tf-medium="snippet"
            style={{
              display: 'inline-block',
              maxWidth: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              backgroundColor: '#0445AF',
              color: '#FFFFFF',
              fontSize: '20px',
              borderRadius: '25px',
              padding: '0 33px',
              fontWeight: 'bold',
              height: '50px',
              cursor: 'pointer',
              lineHeight: '50px',
              textAlign: 'center',
              margin: 0,
              textDecoration: 'none'
            }}
          >
            Launch me
          </button>
        </div>
        <ButtonClose handler={() => closeModal()} />
      </Modal> */}
      {/* <Widget
        id="N1z3n4LD"
        iframe-props="title=Service Provider Account Request"
        medium="snippet"
        className="popup-form"
      /> */}
    </>
  );
};
