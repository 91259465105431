import { useState } from 'react';
import classNames from 'classnames';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { IImage } from 'models/Image';
import { IMenuItem } from 'models/sliderMenu';

import { getImagePath } from 'utils/helpers';

import styles from './BlockUl.module.scss';
import { useMobile } from 'utils/hooks';
import MobileSlider from './mobileSlider/MobileSlider';
import LinkToSeeMore from 'components/linkToSeeMore/LinkToSeeMore';
import { isEmpty } from 'lodash';

interface IProps {
  backgroundImages?: IImage[];
  title?: string;
  menu: IMenuItem[];
  isFullWidth?: boolean;
}

export const BlockUl = ({ backgroundImages, title, menu, isFullWidth }: IProps) => {
  const [activeNavItemContent, setActiveNavItemContent] = useState<IMenuItem>(menu[0]);
  const { isMobile } = useMobile(548);

  const mainImage = activeNavItemContent?.fields?.images?.find((i: IImage) => getImagePath(i, '{mainimage}'));
  const slideImage = activeNavItemContent?.fields?.images?.find((i: IImage) => getImagePath(i, '{slidebg}'));
  const backgroundImage = backgroundImages?.find((i: IImage) => getImagePath(i, '{desktop bg}'));
  const slideBackground = backgroundImages?.find((i: IImage) => getImagePath(i, '{slidebg}'));
  const slideBackgroundMobile = backgroundImages?.find((i: IImage) => getImagePath(i, '{slidebg mobile}'));

  const changeTab = (content: IMenuItem) => () => {
    setActiveNavItemContent(content);
  };

  if (isMobile) {
    return (
      <div className={styles.mobileSliderWrapper}>
        <MobileSlider
          slideBackground={slideBackgroundMobile}
          menu={menu}
          currentMainImage={mainImage}
          title={title || ''}
        />
      </div>
    );
  }

  return (
    <section className={styles.blockUlWrapper}>
      <div
        style={{
          backgroundImage: backgroundImage?.fields?.file?.url ? `url(${backgroundImage?.fields?.file?.url})` : ''
        }}
        className={styles.blockUl}
      >
        <nav className={styles.blockUlNavigation}>
          {title && <h2 className={styles.blockUlNavigationTitle}>{title}</h2>}
          <ul className={styles.blockUlNavigationList}>
            {menu?.map((listItem) => (
              <div
                key={listItem.sys.id}
                className={classNames(styles.blockUlNavigationListWrapper, {
                  [styles.active]: listItem.fields.title === activeNavItemContent?.fields.title
                })}
              >
                <div className={styles.blockUlNavigationListWrapperPoint} />
                <li
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  onMouseDown={changeTab(listItem)}
                  className={styles.blockUlNavigationListWrapperItem}
                >
                  {listItem?.fields?.title}
                </li>
              </div>
            ))}
          </ul>
        </nav>
        <div className={styles.blockUlImageWithContent}>
          {!isEmpty(slideBackground) && (
            <img
              alt={slideBackground?.fields?.description}
              className={styles.slideBackground}
              src={slideBackground?.fields?.file?.url}
            />
          )}
          {isEmpty(slideBackground) && slideImage && (
            <img
              alt={slideImage.fields?.description}
              className={styles.slideBackground}
              src={slideImage.fields?.file?.url}
            />
          )}
          <img alt={mainImage?.fields?.description} className={styles.image} src={mainImage?.fields?.file?.url} />
          <div className={styles.content}>{documentToReactComponents(activeNavItemContent?.fields?.content)}</div>
          {activeNavItemContent?.fields?.linkToSeeMore && (
            <LinkToSeeMore linkItem={activeNavItemContent?.fields?.linkToSeeMore} />
          )}
        </div>
      </div>
    </section>
  );
};
