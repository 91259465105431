import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

import Button from 'components/button/Button';

import { IImage } from 'models/Image';
import { buttonCb, IButtonField } from 'models/button';

import { getImagePath } from 'utils/helpers';
import { useMobile } from 'utils/hooks';

import styles from './Banner.module.scss';

interface IProps {
  backgroundColor: string;
  content?: any; //rich text type
  button?: IButtonField;
  imagePosition?: string;
  isFullWidth?: boolean;
  images?: IImage[];
  typeOfBanner: 'home' | 'slider' | 'simpleContent';
  children?: JSX.Element | JSX.Element[];
  onButtonClick?: buttonCb;
  withLine?: boolean;
  syncClass?: any;
  delay?: number;
}

export const Banner = ({
  images,
  backgroundColor,
  typeOfBanner,
  content,
  button,
  isFullWidth,
  imagePosition,
  children,
  onButtonClick,
  withLine,
  syncClass,
  delay
}: IProps) => {
  const { isMobile } = useMobile(568);

  const mobileDevice = images?.find((i) => getImagePath(i, '{mobiledevice}'));
  const desktopDevice = images?.find((i) => getImagePath(i, '{device}'));

  SwiperCore.use([Autoplay]);

  const bannerContentRender = () => {
    switch (typeOfBanner) {
      case 'slider':
        return (
          <section className={`${styles.bannerWrapper} ${isFullWidth ? '' : 'container'}`}>
            <div
              style={{ backgroundColor }}
              className={classNames(styles.bannerWrapperBanner, {
                [syncClass]: syncClass
              })}
            >
              <Swiper
                centeredSlides
                autoplay={{
                  delay: 1,
                  disableOnInteraction: false
                }}
                speed={delay ? delay * 1000 : 2000}
                loop
                freeMode
                slidesPerView="auto"
                breakpoints={{
                  320: {
                    spaceBetween: 40
                  },
                  1440: {
                    spaceBetween: 150
                  }
                }}
                allowTouchMove={false}
              >
                {images?.map((i) => (
                  <SwiperSlide key={i.sys.id}>
                    <div className={classNames(styles.slideWrapper, { [styles.sliderSync]: !backgroundColor })}>
                      <img src={i.fields.file.url} alt={i.fields.description} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            {withLine && <hr className={styles.withLine}/>}
          </section>
        );
      case 'home':
        return (
          <div style={{ backgroundColor }} className={styles.bannerContentHome}>
            {content}
            {button?.fields?.title && (
              <Button
                title={button?.fields?.title}
                backgroundColor={button?.fields?.backgroundColor}
                handler={onButtonClick && onButtonClick(button?.fields?.link)}
                type={button?.fields?.backgroundVariant || 'third'}
              />
            )}
            {isMobile && (
              <img
                key={mobileDevice?.sys.id}
                src={mobileDevice?.fields.file.url}
                alt={mobileDevice?.fields.description}
                className={styles.bannerContentHomeImageMobile}
              />
            )}
            {!isMobile && (
              <img
                key={desktopDevice?.sys.id}
                src={desktopDevice?.fields.file.url}
                alt={desktopDevice?.fields.description}
                className={styles.bannerContentHomeImageDesktop}
              />
            )}
          </div>
        );
      case 'simpleContent':
        return (
          <div className={styles.simpleBanner} style={{ backgroundColor }}>
            {children}
          </div>
        );
      default:
        return null;
    }
  };
  return <>{bannerContentRender()}</>;
};
