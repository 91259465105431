import classNames from 'classnames';

import {IImage} from "models/Image";

import styles from './Quotation.module.scss';

interface IProps {
  images: IImage[];
  imagePosition: string;
  content: any; //rich text type
}

export const Quotation = ({ content, imagePosition, images }: IProps) => {
  const quotes: any = images?.find((i: any) => i?.fields?.title?.toLowerCase().includes('quotes'));

  return (
    <section
      className={classNames(styles.quotation, {
        [styles.left]: imagePosition === 'left',
        [styles.right]: imagePosition === 'right',
        [styles.top]: imagePosition === 'top',
        [styles.bottom]: imagePosition === 'bottom'
      })}
    >
      <img
        src={quotes?.fields?.file.url}
        alt={quotes?.fields?.description}
        className={styles.quotationImage}
      />
      <div className={styles.quotationContent}>{content}</div>
    </section>
  );
};
