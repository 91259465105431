import styles from './Loader.module.scss';

export const Loader = () => (
  <div className={styles.loader}>
    <svg
      className={styles.loader__icon}
      width="69"
      height="85"
      viewBox="0 0 69 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 16.3378C0 14.186 1.74438 12.4416 3.89619 12.4416C6.048 12.4416 7.79238 14.186 7.79238 16.3378V69.5093C7.79238 71.6611 6.048 73.4055 3.89619 73.4055C1.74438 73.4055 0 71.6611 0 69.5093V16.3378Z"
        fill="url(#paint0_linear_1612_709)"
      />
      <path
        d="M15.2137 10.117C15.2137 7.96519 16.9581 6.22081 19.1099 6.22081C21.2617 6.22081 23.0061 7.96519 23.0061 10.117V75.7301C23.0061 77.882 21.2617 79.6263 19.1099 79.6263C16.9581 79.6263 15.2137 77.882 15.2137 75.7301V10.117Z"
        fill="url(#paint1_linear_1612_709)"
      />
      <path
        d="M30.4274 3.89619C30.4274 1.74438 32.1718 0 34.3236 0C36.4754 0 38.2198 1.74438 38.2198 3.89619V35.2949C38.2198 37.4467 36.4754 39.1911 34.3236 39.1911C32.1718 39.1911 30.4274 37.4467 30.4274 35.2949V3.89619Z"
        fill="url(#paint2_linear_1612_709)"
      />
      <path
        d="M30.4274 49.3081C30.4274 47.1563 32.1718 45.4119 34.3236 45.4119C36.4754 45.4119 38.2198 47.1563 38.2198 49.3081V80.7068C38.2198 82.8586 36.4754 84.603 34.3236 84.603C32.1718 84.603 30.4274 82.8586 30.4274 80.7068V49.3081Z"
        fill="url(#paint3_linear_1612_709)"
      />
      <path
        d="M68.6471 16.3378C68.6471 14.186 66.9028 12.4416 64.751 12.4416C62.5992 12.4416 60.8548 14.186 60.8548 16.3378V69.5093C60.8548 71.6611 62.5992 73.4055 64.751 73.4055C66.9028 73.4055 68.6471 71.6611 68.6471 69.5093V16.3378Z"
        fill="url(#paint4_linear_1612_709)"
      />
      <path
        d="M53.4335 10.117C53.4335 7.96519 51.6891 6.22081 49.5373 6.22081C47.3855 6.22081 45.6411 7.96519 45.6411 10.117V75.7301C45.6411 77.882 47.3855 79.6263 49.5373 79.6263C51.6891 79.6263 53.4335 77.882 53.4335 75.7301V10.117Z"
        fill="url(#paint5_linear_1612_709)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1612_709"
          x1="34.3236"
          y1="0"
          x2="34.3236"
          y2="84.603"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CB1CB1" />
          <stop offset="1" stopColor="#E010C2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1612_709"
          x1="34.3236"
          y1="0"
          x2="34.3236"
          y2="84.603"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CB1CB1" />
          <stop offset="1" stopColor="#E010C2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1612_709"
          x1="34.3236"
          y1="0"
          x2="34.3236"
          y2="84.603"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CB1CB1" />
          <stop offset="1" stopColor="#E010C2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1612_709"
          x1="34.3236"
          y1="0"
          x2="34.3236"
          y2="84.603"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CB1CB1" />
          <stop offset="1" stopColor="#E010C2" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1612_709"
          x1="34.3236"
          y1="0"
          x2="34.3236"
          y2="84.603"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CB1CB1" />
          <stop offset="1" stopColor="#E010C2" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1612_709"
          x1="34.3236"
          y1="0"
          x2="34.3236"
          y2="84.603"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CB1CB1" />
          <stop offset="1" stopColor="#E010C2" />
        </linearGradient>
      </defs>
    </svg>
    <svg
      width="64"
      height="18"
      viewBox="0 0 64 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.loader__text}
    >
      <path
        d="M3.83861 17.207H0.458618V0.318848H3.83861V7.07884H10.5868V0.318848H13.9668V17.207H10.5868V10.4588H3.83861V17.207Z"
        fill="#F9F9F9"
      />
      <path
        d="M26.5164 17.207H23.1482V11.9192C22.4023 11.7307 21.7153 11.4285 21.0872 11.0124C20.4669 10.5962 19.933 10.1016 19.4855 9.52845C19.038 8.94745 18.6886 8.30757 18.4374 7.6088C18.194 6.90218 18.0723 6.16023 18.0723 5.38295V0.318848H21.4523V5.38295C21.4523 5.84618 21.5386 6.28585 21.7114 6.70197C21.8919 7.11024 22.1353 7.46748 22.4415 7.77368C22.7477 8.07988 23.105 8.32327 23.5132 8.50385C23.9294 8.67658 24.369 8.76295 24.8323 8.76295C25.2955 8.76295 25.7312 8.67658 26.1395 8.50385C26.5556 8.32327 26.9168 8.07988 27.223 7.77368C27.5292 7.46748 27.7687 7.11024 27.9414 6.70197C28.122 6.28585 28.2123 5.84618 28.2123 5.38295V0.318848H31.5805V5.38295C31.5805 6.16023 31.4549 6.90218 31.2036 7.6088C30.9602 8.30757 30.6148 8.94745 30.1672 9.52845C29.7197 10.1016 29.1858 10.5962 28.5656 11.0124C27.9453 11.4285 27.2623 11.7307 26.5164 11.9192V17.207Z"
        fill="#F9F9F9"
      />
      <path
        d="M48.5935 0.318848L43.0466 17.207H39.6666L34.1432 0.318848H37.9824L41.3507 11.5894L44.7307 0.318848H48.5935Z"
        fill="#F9F9F9"
      />
      <path
        d="M63.6045 17.207H52.0277V0.318848H63.6045V3.69884H55.4077V7.07884H60.9546V10.4588H55.4077V13.8271H63.6045V17.207Z"
        fill="#F9F9F9"
      />
    </svg>
  </div>
);
