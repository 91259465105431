import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import isEmpty from 'lodash/isEmpty';

import { Button } from 'components';

import styles from './Title.module.scss';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

interface IProps {
  content: any; // rich text type
  imagePosition: string;
  background: {
    fields: {
      description: string;
      file: {
        contentType: string;
        details: any;
        fileName: string;
        url: string;
      };
    };
    metadata: any;
    sys: any;
  };
  buttons?: {
    fields: {
      backgroundColor: string;
      entryName: string;
      slug: string;
      title: string;
      link?: string;
      backgroundVariant?: 'primary' | 'secondary' | 'third';
    };
    metadata: any;
    sys: any;
  }[];
}

export const Title = ({ background, imagePosition, content, buttons }: IProps) => {
  const navigate = useNavigate();

  const onButtonClick = useCallback((path?: string) => () => navigate(path || ''), [navigate]);

  return (
    <section className={styles.titleSection}>
      <div
        style={{
          backgroundImage: isEmpty(background) ? '' : `url(${background?.fields?.file?.url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'bottom'
        }}
        className={styles.titleWrapper}
      >
        <div className={styles.titleSectionContent}>{documentToReactComponents(content)}</div>
        <div className={styles.titleSectionButtons}>
          {buttons?.map((button) => (
            <Button
              key={button.sys.id}
              title={button?.fields?.title}
              backgroundColor={button?.fields?.backgroundColor}
              handler={onButtonClick(button?.fields?.link)}
              href={button?.fields?.link}
              type={button?.fields?.backgroundVariant || 'primary'}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
