/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import {IMenuItemTabs} from "models/tabs";

import { Banner, BlockUl } from 'components';

import styles from './Tabs.module.scss';

interface ITabItem {
  fields: {
    content: {
      fields: {
        backgroundColor: string;
        content: any; // rich text type
        entryName: string;
        imagePosition: string;
        isFullWidth: string;
      };
      metadata: any;
      sys: any;
    };
    title: string;
  };
  metadata: any;
  sys: any;
}

interface IProps {
  isFullWidth: boolean;
  tabs: {
    fields: {
      contentModules: ITabItem[];
      entryName: string;
    };
    metadata: any;
    sys: any;
  };
  menu: IMenuItemTabs[];
  backgroundImages: [];
}

export const Tabs = ({ isFullWidth, tabs, menu,  backgroundImages }: IProps) => {
  const availableTabs = tabs?.fields?.contentModules;

  return (
    <section>
      <div className={styles.tabsBlock}>
        <div className={` ${isFullWidth ? '' : 'container'} ${styles.tabsBlockTabs}`}>
          {availableTabs?.map((availableTab) => (
            <a className={styles.anchor} href={`#${availableTab?.fields?.title}`} key={availableTab?.sys?.id}>
              <div
                key={availableTab?.sys?.id}
                className={classNames(
                  styles.tabsBlockTabsTabWrapper
                )}
              >
                <div
                  className={styles.tabsBlockTabsTabWrapperTab}
                >
                  {availableTab?.fields?.title}
                </div>
              </div>
            </a>
          ))}
        </div>
        <Banner
          backgroundColor={
            availableTabs && availableTabs.length ? availableTabs[0]?.fields?.content?.fields?.backgroundColor : ''
          }
          typeOfBanner="simpleContent"
        >
          <div className={styles.tabsBlockTabsBannerContent}>
            {documentToReactComponents(
              availableTabs && availableTabs.length ? availableTabs[0]?.fields?.content?.fields?.content : ''
            )}
          </div>
        </Banner>
        {menu?.map((blockContent: IMenuItemTabs, idx: number) => (
          <div
            id={availableTabs && availableTabs.length ? availableTabs[idx].fields?.title : ''}
            key={blockContent?.sys?.id}
          >
            <BlockUl
              key={blockContent?.sys?.id}
              title={blockContent.fields.content}
              menu={blockContent.fields.contentModules || []}
              isFullWidth={false}
              backgroundImages={backgroundImages}
            />
          </div>
        ))}
      </div>
    </section>
  );
};
