import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { IbaseField } from 'models/menuItem';
import { buttonCb } from 'models/button';
import { IImage, IImageFields } from 'models/Image';

import { Button } from 'components';

import { findExistingImageFields, getImagePath } from 'utils/helpers';

import styles from './Block.module.scss';
import LinkToSeeMore from 'components/linkToSeeMore/LinkToSeeMore';
import { ILink } from 'models/link';

interface IButton extends IbaseField {
  fields: {
    backgroundColor: string;
    entryName: string;
    slug: string;
    title: string;
    link: string;
    backgroundVariant: 'primary' | 'secondary' | 'third';
  };
}

interface IProps {
  backgroundColor: string;
  content: any; // rich text type
  buttons: IButton[];
  imagePosition: string;
  isFullWidth: boolean;
  images: IImage[];
  isMobile: boolean;
  commonClasses?: {
    left: string;
    right: string;
  }; //additional css classes
  onButtonClick?: buttonCb;
  isBottomAlign: boolean;
  isLastItem: boolean;
  isSmallContent: boolean;
  linkToSeeMore?: ILink;
  isLeftAlign?: boolean;
}

const defaultProps = {
  commonClasses: ''
};

export const Block = ({
  content,
  isMobile,
  buttons,
  isFullWidth,
  backgroundColor,
  imagePosition,
  images,
  commonClasses,
  onButtonClick,
  isBottomAlign,
  isLastItem,
  isSmallContent,
  linkToSeeMore,
  isLeftAlign
}: IProps) => {
  const mainImage: IImageFields = findExistingImageFields(images, isMobile);

  const hugeLogo: IImageFields = images?.find((i: IImage) => getImagePath(i, '{hugelogo}'))?.fields;
  const backgroundImage = images?.find((i: IImage) => getImagePath(i, '{section bg}'));

  const sectionBg: any = backgroundImage?.fields;

  const mobileHelp: any = images?.find((i: any) => getImagePath(i, '{mobile help}'))?.fields;

  function getBg(isMobile: boolean, sectionBg: { file: { url: string } }, mobileHelp: { file: { url: string } }) {
    if (isMobile) return mobileHelp?.file?.url;

    if (!isEmpty(sectionBg)) return sectionBg?.file?.url;
    return '';
  }

  const imageRender = () => {
    if (imagePosition === 'none') return null;

    if (!isEmpty(hugeLogo)) {
      return (
        <div className="hugeLogoBottomAlign">
          <img src={hugeLogo?.file?.url} alt={hugeLogo?.description} className={classNames(['blockContentImage'])} />
        </div>
      );
    }

    return (
      <div
        className={classNames('blockContentImage', {
          blockAlignLeft: isLeftAlign
        })}
      >
        <img src={mainImage?.file?.url} alt={mainImage?.description} />
      </div>
    );
  };
  const blockContentClasses = {
    hugeLogoContent: !isEmpty(hugeLogo),
    helpSection: isLastItem,
    smallContent: isSmallContent
  };
  if (commonClasses) {
    const { left, right } = commonClasses;

    if (left) blockContentClasses[left as keyof typeof blockContentClasses] = imagePosition === 'left';
    if (right) blockContentClasses[right as keyof typeof blockContentClasses] = imagePosition === 'right';
  }
  return (
    <section className="blockWrapper">
      <div
        style={{
          backgroundColor,
          backgroundImage: getBg(isMobile, sectionBg, mobileHelp)
            ? `url(${getBg(isMobile, sectionBg, mobileHelp)})`
            : '', // TODO: refactor
          backgroundSize: 'cover',
          backgroundPosition: 'bottom'
        }}
        className={classNames(`block ${isFullWidth ? '' : 'container'}`, {
          left: imagePosition === 'left',
          right: imagePosition === 'right',
          top: imagePosition === 'top',
          bottom: imagePosition === 'bottom',
          center: imagePosition === 'none',
          defaultContent: isEmpty(buttons),
          blockWithHugeLogo: !isEmpty(hugeLogo),
          blockAlignBottom: isBottomAlign
        })}
      >
        <div className={classNames('blockContent', blockContentClasses)}>
          <div className="blockContentContent">{content}</div>
          {!isEmpty(buttons) && (
            <div className="buttons">
              {buttons?.map((button) => (
                <Button
                  key={button?.sys?.id}
                  title={button?.fields?.title || ' '}
                  backgroundColor={button?.fields?.backgroundColor || ' '}
                  buttonClass="button"
                  handler={onButtonClick && onButtonClick(button?.fields?.link)}
                  href={button?.fields?.link}
                  type={button?.fields?.backgroundVariant || 'primary'}
                />
              ))}
            </div>
          )}
          {!isEmpty(linkToSeeMore) && <LinkToSeeMore linkItem={linkToSeeMore} />}
        </div>
        {imageRender()}
      </div>
    </section>
  );
};

Block.defaultProps = defaultProps;
