/* eslint-disable no-shadow */
import React from 'react';
import classNames from 'classnames';
import ReactPlayer from 'react-player';
import isEmpty from 'lodash/isEmpty';
import { useMobile } from 'utils/hooks';

import { getImagePath } from 'utils/helpers';

import { Button } from 'components';

import { buttonCb, IButtonField } from 'models/button';
import { IImage, IImageFields } from 'models/Image';

import styles from './Section.module.scss';

interface SectionProps {
  content: any; // rich text type
  images: IImage[];
  imagePosition: string;
  video?: any;
  buttons?: IButtonField[];
  isLastItem: boolean;
  isFullWidth: boolean;
  onButtonClick?: buttonCb;
  sectionName: string;
  isSmallContent: boolean;
}
type urlT = string | undefined;

const Section = ({
  content,
  images,
  imagePosition,
  isLastItem,
  video,
  isFullWidth,
  buttons,
  onButtonClick,
  sectionName,
  isSmallContent
}: SectionProps) => {
  const { isMobile } = useMobile(568); // to const

  const isSimilarToBlock = !!sectionName && sectionName.split(' ').includes('{Block}');

  const sectionBg: IImageFields = images?.find((i: IImage) => getImagePath(i, '{section bg}'))?.fields;
  const mobileBg: IImageFields = images?.find((i: IImage) => getImagePath(i, '{mobilebg}'))?.fields;
  const mainImage: IImageFields = images?.find((i: IImage) => getImagePath(i, '{mainimage}'))?.fields;
  const mainImageBg: IImageFields = images?.find((i: IImage) => getImagePath(i, '{mainimage bg}'))?.fields;
  const mainVideo: urlT = images?.find((i: IImage) => getImagePath(i, '{video}'))?.fields?.file?.url;
  const device = images?.find((i: IImage) => getImagePath(i, `{device} ${isMobile ? 'x1' : 'x2'}`))?.fields;
  const playVideoIcon: urlT = images?.find((i: IImage) => getImagePath(i, '{icon player}'))?.fields.file?.url;
  const preview = images?.find((i: IImage) => getImagePath(i, '{preview}'))?.fields.file?.url;
  const chatImg: IImageFields = images?.find((i: IImage) => getImagePath(i, '{chat}'))?.fields;
  const deviceLogo: IImageFields = images?.find((i: IImage) => getImagePath(i, '{insteaddevice}'))?.fields;
  const mobileHelp: IImageFields = images?.find((i: IImage) => getImagePath(i, '{mobile help}'))?.fields;
  const groupLogo: IImageFields = images?.find((i: IImage) => getImagePath(i, '{grouplogo}'))?.fields;
  const logo = images?.find((i: IImage) => i?.fields?.title?.toLowerCase().includes('logo'))?.fields?.file?.url;

  function getBg(isMobile: boolean, sectionBg?: { file: { url: string } }, mobileHelp?: { file: { url: string } }) {
    if (isMobile) return mobileHelp?.file?.url;

    if (!isEmpty(sectionBg)) return sectionBg?.file?.url;
    return '';
  }

  return (
    <section className={classNames([styles.section, 'section'])}>
      <div
        className={classNames(`${!isFullWidth ? 'container' : ''}`, {
          wrapper: true,
          right: imagePosition === 'right',
          left: imagePosition === 'left',
          top: imagePosition === 'top',
          bottom: imagePosition === 'bottom',
          fullWidth: isFullWidth,
          none: imagePosition === 'none',
          reverseDirection: isSimilarToBlock,
          deviceLogoControl: !isEmpty(deviceLogo)
        })}
        style={{
          backgroundImage: getBg(isMobile, sectionBg, mobileHelp)
            ? `url(${getBg(isMobile, sectionBg, mobileHelp)})`
            : '', // TODO: refactor
          backgroundSize: 'cover',
          backgroundPosition: 'bottom'
        }}
      >
        {!isEmpty(video) && (
          <div className="videoWrapper">
            <div style={{ height: '100%', position: 'absolute', width: '100%', top: '0', left: '0' }}>
              <ReactPlayer
                width="100%"
                height="100%"
                playIcon={
                  <div className="videoPlayerIcon">
                    <img src={playVideoIcon} />
                  </div>
                }
                light={preview}
                config={{ youtube: { playerVars: { disablekb: 1 } } }}
                controls
                url={mainVideo}
              />
            </div>
          </div>
        )}

        {!isEmpty(groupLogo) && <img src={groupLogo?.file?.url} alt={groupLogo?.description} className="groupLogo" />}

        {!isEmpty(mainImage) && (
          <img src={mainImage?.file?.url} alt={mainImage?.description} className="sectionImage" />
        )}

        {!isEmpty(mainImageBg) && isEmpty(device) && isEmpty(deviceLogo) && (
          <img src={mainImageBg?.file?.url} alt={mainImageBg?.description} className="sectionBackgroundImage" />
        )}

        {(!isEmpty(device) || !isEmpty(deviceLogo)) && (
          <div
            className={classNames('backgroundOverlay')}
            style={{ backgroundImage: `url(${isMobile ? mobileBg?.file?.url : mainImageBg?.file?.url})` }}
          >
            <img
              src={device?.file.url || deviceLogo?.file.url}
              alt={mainImageBg?.description}
              className={classNames('', {
                chatControl: !isEmpty(chatImg),
                device: !isEmpty(device)
              })}
            />
          </div>
        )}
        <div
          className={classNames('content', {
            contentMargin: isEmpty(video) && !isSimilarToBlock,
            groupLogoControl: !isEmpty(groupLogo),
            helpSection: isLastItem,
            mobileContent: imagePosition === 'left',
            contentLeft: imagePosition === 'left',
            isSimilarToBlock,
            smallContent: isSmallContent,
            deviceLogoControl: !isEmpty(deviceLogo)
          })}
        >
          {logo && <img src={logo} alt="" />}
          {content}
          <div className="buttons">
            {buttons?.map((i) => (
              <Button
                title={i?.fields?.title}
                key={i?.sys?.id}
                backgroundColor={i?.fields?.backgroundColor}
                handler={onButtonClick && onButtonClick(i?.fields?.link)}
                href={i?.fields?.link}
                type={i?.fields?.backgroundVariant || 'primary'}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section;
