import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import { IImage, IImageFields } from 'models/Image';
import { ILink } from 'models/link';

import { getImagePath } from 'utils/helpers';
import { useMobile } from 'utils/hooks';

import styles from './DeviceBlock.module.scss';

interface IProps {
  backgroundColor: string;
  content: any;
  imagePosition: string;
  isFullWidth: boolean;
  images: IImage[];
  isMobile: boolean;
  learnMore?: ILink;
  isSmallContent: boolean;
}

export const DeviceBlock = ({
  content,
  isMobile,
  isFullWidth,
  backgroundColor,
  imagePosition,
  images,
  learnMore,
  isSmallContent
}: IProps) => {
  const mainImage: IImageFields = images?.find((image: IImage) =>
    getImagePath(image, `{device-${isMobile ? 'mobile' : 'desktop'}`)
  )?.fields;
  const dots: IImageFields = images?.find((image: IImage) => getImagePath(image, '{dots}'))?.fields;
  const arrow = learnMore?.fields?.images?.find((i: IImage) => getImagePath(i, 'arrow'));
  const backgroundImage = images?.find((i) => getImagePath(i, '{section bg}'));

  const isLaptop = useMobile(1280).isMobile;

  const imageRender = () => {
    if (imagePosition === 'none') return null;

    return <img src={mainImage?.file?.url} alt={mainImage?.description} className={styles.image} />;
  };

  return (
    <section
      style={{
        backgroundColor,
        backgroundImage: backgroundImage?.fields?.file?.url ? `url(${backgroundImage?.fields?.file?.url})` : ''
      }}
      className={styles.deviceBlockWrapper}
    >
      <div
        className={classNames(`${styles.deviceBlockWrapperBlock} ${isFullWidth ? '' : 'container'}`, {
          [styles.left]: imagePosition === 'left',
          [styles.right]: imagePosition === 'right',
          [styles.top]: imagePosition === 'top',
          [styles.bottom]: imagePosition === 'bottom',
          smallContent: isSmallContent
        })}
      >
        <div className={styles.deviceBlockWrapperBlockContent}>
          <div className={styles.deviceBlockWrapperBlockContentContent}>{content}</div>
          {!isEmpty(learnMore) && (
            <div className={styles.linkWithIcon}>
              <Link to={learnMore?.fields?.link || ''} className={styles.link}>
                {learnMore?.fields?.title}
              </Link>
              <img src={arrow?.fields?.file?.url} />
            </div>
          )}
        </div>
        <div
          className={classNames('_', {
            [styles.imageWithDots]: !isLaptop,
            [styles.imageWithoutDots]: isLaptop
          })}
        >
          {!isLaptop && <img src={dots?.file?.url} alt="" className={styles.dots} />}
          {imageRender()}
        </div>
      </div>
    </section>
  );
};
