import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash/get';

import { RootState, useAppDispatch } from 'redux/index';
import { getPageFetch } from 'redux/slices/page';

// eslint-disable-next-line import/named
import { Block, Tabs, Typography, Loader } from 'components';
import { DeviceBlock } from '../deviceBlock/DeviceBlock';

import { slugs } from 'utils/constants';
import { useMobile } from 'utils/hooks';
import { useNavigate } from 'react-router-dom';

export const Forge = () => {
  const dispatch = useAppDispatch();
  const { contentModules, isLoading } = useSelector((state: RootState) => state.page);
  const { isMobile } = useMobile(568);
  const navigate = useNavigate();

  const onButtonClick = useCallback((path?: string) => () => navigate(path || ''), [navigate]);

  useEffect(() => {
    dispatch(getPageFetch(slugs.products_forge));
  }, []);

  const contentRender = () =>
    contentModules?.map((i: any) => {
      const isFullWidth = i?.fields?.isFullWidth === 'Yes';
      switch (i?.fields?.slug) {
        case slugs.section:
          return (
            <Block
              isBottomAlign={i?.fields?.isBottomAlign === 'yes'}
              isSmallContent={i?.fields?.isSmallContent === 'Yes'}
              isFullWidth={isFullWidth}
              key={i?.sys?.id}
              content={documentToReactComponents(i?.fields?.content)}
              buttons={i?.fields?.contentModules?.filter((i: any) => i?.fields?.slug === 'button')}
              backgroundColor={get(i, 'fields.backgroundColor', '')}
              imagePosition={get(i, 'fields.imagePosition', 'left')}
              images={get(i, 'fields.images', [])}
              isMobile={isMobile}
              onButtonClick={onButtonClick}
              isLastItem={i === contentModules?.length - 1}
            />
          );
        case slugs.tabs_block:
          return (
            <Tabs
              key={i?.sys?.id}
              isFullWidth={isFullWidth}
              tabs={get(i, 'fields.tabs', [])}
              menu={get(i, 'fields.contentList', [])}
              backgroundImages={get(i, 'fields.backgroundImages', [])}
            />
          );
        case slugs.device:
          return (
            <DeviceBlock
              isSmallContent={i?.fields?.isSmallContent === 'Yes'}
              isFullWidth={isFullWidth}
              key={i?.sys?.id}
              content={documentToReactComponents(i?.fields?.content)}
              backgroundColor={get(i, 'fields.backgroundColor', '')}
              imagePosition={get(i, 'fields.imagePosition', 'left')}
              images={get(i, 'fields.images', [])}
              isMobile={isMobile}
              learnMore={get(i, 'fields.contentModules', [])?.find((i: any) => i?.fields?.slug === 'link')}
            />
          );
        case slugs.typography:
          return <Typography key={i?.sys?.id} isProducts content={documentToReactComponents(i?.fields?.content)} />;
        default:
          return null;
      }
    });

  if (isLoading) return <Loader />;

  return <main>{contentRender()}</main>;
};
