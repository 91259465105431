/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import { IMenuItem } from 'models/menuItem';
import { MouseEventHandler, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavSublist } from '../NavSubList/NavSubList';
import styles from './NavList.module.scss';

interface NavListProps {
  menu: IMenuItem[];
  isMobile?: boolean;
  mobileOffset?: number;
  setMobileOffset?: (x: number) => void;
  setIsOpen?: (x: boolean | ((prev: boolean) => boolean)) => void;
  setShownId: (x: string) => void;
  shownId: string;
  navigate?: any;
}

export const Navlist = ({
  menu,
  isMobile,
  mobileOffset,
  setMobileOffset,
  setIsOpen,
  setShownId,
  shownId,
  navigate
}: NavListProps) => {
  const subMenuItem = (shownId && menu.find((el) => el.sys.id === shownId)) || undefined;

  const arrowIcon = (
    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.954505 1.2045C1.39384 0.765165 2.10616 0.765165 2.5455 1.2045L8.5455 7.2045C8.98483 7.64384 8.98483 8.35616 8.5455 8.7955L2.5455 14.7955C2.10616 15.2348 1.39384 15.2348 0.954505 14.7955C0.515165 14.3562 0.515165 13.6438 0.954505 13.2045L6.15901 8L0.954505 2.7955C0.515165 2.35616 0.515165 1.64384 0.954505 1.2045Z"
        fill="#F9F9F9"
      />
    </svg>
  );

  const arrowIconDown = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5303 8.96967C16.8232 9.26256 16.8232 9.73744 16.5303 10.0303L12.5303 14.0303C12.2374 14.3232 11.7626 14.3232 11.4697 14.0303L7.46967 10.0303C7.17678 9.73744 7.17678 9.26256 7.46967 8.96967C7.76256 8.67678 8.23744 8.67678 8.53033 8.96967L12 12.4393L15.4697 8.96967C15.7626 8.67678 16.2374 8.67678 16.5303 8.96967Z"
        fill="#F2F2F2"
      />
    </svg>
  );

  const handleClick = (menuItem: IMenuItem, elem: HTMLLinkElement | null) => {
    if (menuItem.fields.subMenu) {
      if (shownId) {
        setShownId('');
      } else {
        setShownId(menuItem?.sys?.id);
      }
    } else if (isMobile && setIsOpen) {
      setIsOpen(false);
      setShownId('');
    }
  };
  const mouseEnterHandle = (menuItem: IMenuItem) => {
    if (!isMobile && menuItem.fields.subMenu) {
      setShownId(menuItem?.sys?.id);
    }
  };

  return (
    <ul role="menu" className={styles.nav__list}>
      {menu.map((menuItem) =>
        menuItem.fields ? (
          <li
            onMouseEnter={() => (isMobile ? null : mouseEnterHandle(menuItem))}
            onMouseLeave={() => (isMobile ? null : setShownId(''))}
            onClick={(e) => handleClick(menuItem, e.target as HTMLLinkElement)}
            onKeyPress={() => mouseEnterHandle(menuItem)}
            className={classNames([styles.nav__item, menuItem?.sys?.id === shownId ? styles.nav__item_active : ''])}
            key={menuItem?.sys?.id}
            role="menuitem"
            aria-label="menu item"
            tabIndex={0}
          >
            {menuItem.fields.subMenu ? (
              <>
                <p>{menuItem?.fields?.title}</p>
                {isMobile && arrowIcon}
                {menuItem?.sys?.id === subMenuItem?.sys?.id && (
                  <NavSublist subMenuItem={subMenuItem} navigate={navigate} />
                )}
              </>
            ) : (
              <Link className={styles.linkWrapper} to={menuItem?.fields?.link || '/soon'}>
                {menuItem?.fields?.title}
                <div className={styles.arrowDown}>{!isMobile && menuItem.fields.subMenu && arrowIconDown}</div>
              </Link>
            )}
          </li>
        ) : null
      )}
    </ul>
  );
};
