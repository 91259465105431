import classNames from 'classnames';
import { IImage } from 'models/menuItem';
import styles from './TitleSection.module.scss';

interface TitleSectionProps {
  title: string;
  backgroundImage: IImage;
}
export const TitleSection = ({ title, backgroundImage }: TitleSectionProps) => (
  <section className={styles.titleSection}>
    <div
      className={classNames([styles.titleSection__container, 'container'])}
      style={{
        backgroundImage: backgroundImage ? `url(${backgroundImage?.fields?.file?.url})` : '',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom'
      }}
    >
      <h2 className={styles.titleSection__title}>{title}</h2>
    </div>
  </section>
);
