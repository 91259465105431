import { IImage } from 'models/Image';

type getImagePathType = (i: any, val: string) => any;

export const getImagePath: getImagePathType = (i, val) => i.fields?.title?.toLowerCase()?.includes(val);

export const findExistingImageFields = (images: IImage[], isMobile: boolean) => {
  if (isMobile) {
    const mobileImage = images.find((image) => getImagePath(image, 'mobile'));
    if (mobileImage) {
      return mobileImage.fields;
    }
  }
  return images.find((image: IImage) => getImagePath(image, 'desktop'))?.fields;
};
