import React, { useCallback } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { IMenuItem } from 'models/menuItem';

import { Logo } from 'components/logo/Logo';

import { slugs } from 'utils/constants';
import { useMobile } from 'utils/hooks';

import styles from './Footer.module.scss';
import { LinkWrapper } from '../linkWrapper/LinkWrapper';
import { useIntercom } from 'react-use-intercom';
import { IImage, IImageFields } from 'models/Image';
import { getImagePath } from 'utils/helpers';

export const Footer = () => {
  const { layout, entryName, isLoading } = useSelector((state: any) => state.page);
  const navigate = useNavigate();
  const { isMobile } = useMobile(568);
  let location = useLocation();
  const { show } = useIntercom();

  const showFooter = location.pathname.includes('soon');
  const activeLink = useCallback((title: string) => entryName.includes(title), [entryName]);

  const footer = get(layout, 'footer.fields', {});
  const logo = footer?.images?.find((i: any) => i?.fields?.title?.toLowerCase().includes(slugs.logo));
  const quoteImg: IImageFields = footer?.images?.find((i: IImage) => getImagePath(i, '{quote}'))?.fields;

  const menu: IMenuItem[] = get(footer, 'menu.fields.contentModules', []);
  const onLinkClick = (slug?: string) => {
    if (slug === 'remove_account') {
      const redirectUrl = '/remove-account-request';
      navigate(redirectUrl, { state: new Date().getTime() });
    } else if (slug === 'chat') {
      show();
    }
  };
  if(isLoading) return null
  return (
    <>
      {showFooter ? null : (
        <footer className={`container ${styles.footer}`}>
          {isMobile && (
            <div className={styles.menuItemMobile}>
              <Logo logo={logo} className={styles.logo} />
              <p className={styles.footerTitle}>{get(footer, 'title')}</p>
              <p className={styles.footerSubtitle}>{get(footer, 'subtitle')}</p>
            </div>
          )}
          <nav className={styles.menu}>
            {!isMobile && (
              <div className={styles.menuItem}>
                <Logo logo={logo} className={styles.logo} />
                <div className={styles.quoteWrapper}>
                  <img className={styles.quoteImage} src={quoteImg?.file.url} alt={quoteImg?.description} />
                  <p className={styles.footerTitle}>{get(footer, 'title')}</p>
                </div>
                <p className={styles.footerSubtitle}>{get(footer, 'subtitle')}</p>
              </div>
            )}
            {menu.map((menuItem) => (
              <div className={styles.menuItem} key={menuItem.sys.id}>
                <p className={styles.menuItemTitle}>{menuItem.fields.title}</p>
                <ul>
                  {menuItem.fields.subMenu.map((subMenuItem) => (
                    <li
                      role="menuitem"
                      key={subMenuItem.sys.id}
                      onMouseDown={['chat', 'remove_account'].includes(subMenuItem?.fields?.slug as string) ? () => onLinkClick(subMenuItem?.fields?.slug) : undefined}
                      className={`${activeLink(subMenuItem?.fields?.title) ? styles.active : ''}`}
                    >
                      {['chat', 'remove_account'].includes(subMenuItem?.fields?.slug as string) ? (
                        subMenuItem?.fields?.title
                      ) : (
                        <LinkWrapper
                          linkUrl={subMenuItem?.fields?.link}
                          isLocal={subMenuItem?.fields?.link?.includes('http')}
                          navigate={navigate}
                        >
                          {subMenuItem?.fields?.title}
                        </LinkWrapper>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </nav>
          <hr />
          <div className={styles.copyRight}>
            <p>{footer.leftSideTitle}</p>
            <p>{footer.rightSideTitle}</p>
          </div>
        </footer>
      )}
    </>
  );
};
