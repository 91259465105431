import {
  AcceptableUsePolicy,
  Asset,
  Company,
  Connect,
  Facility,
  Forge,
  GDPR,
  Home,
  PasswordPolicy,
  PrivacyPolicy,
  Solutions,
  Soon,
  Sync,
  TermsConditions,
  TermsOfService,
  SyncPage
} from 'pages';
import { CookiesPolicy } from 'pages/policy/cookiesPolicy/CookiesPolicy';
import { ComponentType } from 'react';

interface RouteValue {
  path: string;
  name: string;
  component: ComponentType;
  rolesAllowed?: string[];
}

const Routes: { [key: string]: RouteValue } = {
  Home: { path: '/', name: 'Home', component: Home },
  Company: { path: '/company', name: 'Company', component: Company },
  Solutions: { path: '/solutions', name: 'Solutions', component: Solutions },
  ProductsFacility: { path: '/products/facility', name: 'ProductsFacility', component: Facility },
  ProductsAsset: { path: '/products/asset', name: 'ProductsAsset', component: Asset },
  ProductsForge: { path: '/products/forge', name: 'ProductsForge', component: Forge },
  ProductsSync: { path: '/products/sync', name: 'ProductsSync', component: Sync },
  ProductsConnect: { path: '/products/connect', name: 'ProductsConnect', component: Connect },
  ComingSoon: { path: '/soon', name: 'ComingSoon', component: Soon },
  TermsOfService: { path: '/terms-services', name: 'TermsOfService', component: TermsOfService },
  AcceptableUsePolicy: { path: '/acceptable', name: 'AcceptableUsePolicy', component: AcceptableUsePolicy },
  GDPR: { path: '/gdpr', name: 'GDPR', component: GDPR },
  PasswordPolicy: { path: '/password-policy', name: 'PasswordPolicy', component: PasswordPolicy },
  PrivacyPolicy: { path: '/privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicy },
  TermsConditions: { path: '/terms-conditions', name: 'TermsConditions', component: TermsConditions },
  CookiesPolicy: { path: '/cookies', name: 'CookiesPolicy', component: CookiesPolicy },
  Sync: { path: '/sync', name: 'Sync', component: SyncPage },
};

export default Routes;
