import classNames from 'classnames';
import Button from 'components/button/Button';
import { Logo } from 'components/logo/Logo';
import get from 'lodash/get';
import { IButtonField } from 'models/button';
import { IMenuItem } from 'models/menuItem';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { slugs } from 'utils/constants';
import { NavBlock } from '../nav/Nav';
import { NavOuter } from '../nav/NavOuter/NavOuter';
import { Burger } from './Burger/Burger';
import Routes from '../../routing/routes';
import styles from './Header.module.scss';

export const Header = () =>
  // { openModal }: { openModal: (url: string) => void }
  {
    const { layout } = useSelector((state: any) => state.page);
    const [isHome, setIsHome] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      setIsHome(location.pathname === Routes.Home.path);
    }, [location]);

    const header = get(layout, 'header.fields', {});
    const logo = header?.images?.find((i: any) => i?.fields?.title?.toLowerCase().includes(slugs.logo));
    const menu: IMenuItem[] = get(header, 'menu.fields.contentModules', []);
    const modules = get(header, 'contentModules', []);
    const buttons = modules.length && modules.map((module: any) => module.fields.slug === 'button' && module);
    const buttonsElems =
      buttons.length &&
      buttons.map((button: IButtonField) => (
        <Button
          buttonClass={styles.header__button}
          title={button?.fields?.title}
          backgroundColor={button?.fields?.backgroundColor}
          key={button.sys.id}
          href="https://infrared-portal.hyvery.cloud/login"
          type={button?.fields?.backgroundVariant || 'secondary'}
        />
      ));

    const [isMobile, setIsMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const maxTabletWidth = 1024;
    const [shownId, setShownId] = useState('');

    useEffect(() => {
      const updateResize = () => {
        const isMobileResolution = document.body.clientWidth <= maxTabletWidth;
        if (!isMobileResolution) setIsOpen(false);
        setIsMobile(isMobileResolution);
      };
      window.addEventListener('resize', updateResize);
      updateResize();
      return () => window.removeEventListener('resize', updateResize);
    }, []);

    return (
      <header className={classNames(styles.header, isOpen ? styles.header_open : '')}>
        <div className={styles.header__inner}>
          <div
            style={{ backgroundColor: !isHome ? 'unset' : '' }}
            className={classNames([styles.header__container, 'container'])}
          >
            <Logo setIsOpen={setIsOpen} logo={logo} />
            {menu.length && (
              <NavBlock
                menu={menu}
                isMobile={isMobile}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setShownId={setShownId}
                shownId={shownId}
                navigate={navigate}
              />
            )}
            {((isMobile && isOpen) || !isMobile) && buttonsElems}
            {isMobile && <Burger isOpen={isOpen} setIsOpen={setIsOpen} />}
          </div>
        </div>
        {/* {!isMobile && subMenu && (
          <NavOuter
            subMenu={subMenu}
            setShownId={setShownId}
            // openModal={openModal}
          />
        )} */}
      </header>
    );
  };
