import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash/get';

import { RootState, useAppDispatch } from '../../redux';
import { getPageFetch } from '../../redux/slices/page';

import { useMobile } from 'utils/hooks';

import { slugs } from 'utils/constants';
import { Banner, Block, Grid, HorizontalList, Loader } from 'components';

import styles from './SyncPage.module.scss';

export const SyncPage = () => {
  const dispatch = useAppDispatch();
  const { contentModules, isLoading } = useSelector((state: RootState) => state.page);

  const { isMobile } = useMobile(568);

  const navigate = useNavigate();

  const onNavigate = useCallback((path?: string) => () => navigate(path || ''), [navigate]);

  useEffect(() => {
    dispatch(getPageFetch(slugs.sync));
  }, []);

  const contentRender = () =>
    contentModules?.map((i: any) => {
      const isFullWidth = i?.fields?.isFullWidth === 'Yes';

      switch (i?.fields?.slug) {
        case slugs.section:
          return (
            <Block
              isBottomAlign={i?.fields?.isBottomAlign === 'yes'}
              isSmallContent={i?.fields?.isSmallContent === 'Yes'}
              isFullWidth={isFullWidth}
              key={i?.sys?.id}
              content={documentToReactComponents(i?.fields?.content)}
              buttons={i?.fields?.contentModules?.filter((i: any) => i?.fields?.slug === 'button')}
              backgroundColor={get(i, 'fields.backgroundColor', '')}
              imagePosition={get(i, 'fields.imagePosition', 'left')}
              images={get(i, 'fields.images', [])}
              isMobile={isMobile}
              isLastItem={i === contentModules?.length - 1}
            />
          );
        case slugs.banner:
          return (
            <Banner
              typeOfBanner="slider"
              button={i?.fields?.contentModules?.find((i: any) => i?.fields?.slug === 'button')}
              key={i?.sys?.id}
              backgroundColor={get(i, 'fields.backgroundColor', '')}
              imagePosition={get(i, 'fields.imagePosition', 'left')}
              images={get(i, 'fields.images', [])}
              withLine
              syncClass={styles.syncBanner}
            />
          );
        case slugs.list:
          return (
            <HorizontalList
              key={i?.sys?.id}
              content={get(i, 'fields.contentModules', [])}
              syncPage
              title={documentToReactComponents(i?.fields?.content)}
            />
          );
        case slugs.grid:
          return (
            <Grid
              key={i?.sys?.id}
              title={documentToReactComponents(i?.fields?.content)}
              images={get(i, 'fields.images', [])}
              gridItems={get(i, 'fields.contentModules', []).filter((i: any) => !i?.fields?.slug)}
              button={get(i, 'fields.contentModules', []).find((i: any) => i?.fields?.slug === 'button')}
              isFullWidth={isFullWidth}
              onNavigate={onNavigate}
            />
          );
        default:
          return null;
      }
    });
  if (isLoading) return <Loader />;
  return <main>{contentRender()}</main>;
};
