import React from 'react';
import { PopupButton } from '@typeform/embed-react';

import { ILink } from 'models/link';
import { IImage } from 'models/Image';

import styles from './LinkToSeeMore.module.scss';

interface IProps {
  linkItem?: ILink;
}

const LinkToSeeMore = ({ linkItem }: IProps) => {
  const icon = linkItem?.fields?.images?.find((i: IImage) => i?.fields?.title?.toLowerCase()?.includes('icon'));
  const iconHover = linkItem?.fields?.images?.find((i: IImage) => i?.fields?.title?.toLowerCase()?.includes('hover'));

  return iconHover?.fields?.file?.url ? (
    <PopupButton
      id={linkItem?.fields?.embedConfig?.id ?? ''}
      className={styles.linkItem}
      {...linkItem?.fields?.embedConfig}
    >
      <span>{linkItem?.fields?.title}</span>
      <div className={styles.linkArrow}>
        {/* <object
          className={styles.linkArrowImage}
          type="image/svg+xml"
          aria-label={linkItem?.sys?.id}
          data={icon?.fields?.file?.url}
        /> */}
        <object
          className={styles.linkArrowImage}
          type="image/svg+xml"
          aria-label={linkItem?.sys?.id}
          data={iconHover?.fields?.file?.url}
        />
      </div>
    </PopupButton>
  ) : null;
};

export default LinkToSeeMore;
