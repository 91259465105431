import classNames from 'classnames';
import { useMobile } from 'utils/hooks';
import styles from './Typography.module.scss';

interface IProps {
  content: any; //rich text type
  additionalStyles?: { [key: string]: string };
  isProducts?: boolean;
  isHome?: boolean;
}

const defaultProps = {
  additionalStyles: {}
};

export const Typography = ({ content, additionalStyles, isProducts, isHome }: IProps) => {
  const classes = {
    [styles.typography]: true,
    [styles.homeTitle]: isHome,
    [styles.productsTitle]: isProducts
  };
  if (additionalStyles) {
    const { solutionsWrapper, solutionsContent } = additionalStyles;

    if (solutionsWrapper) classes[solutionsWrapper] = !!solutionsWrapper;
    if (solutionsContent) classes[solutionsContent] = !!solutionsContent;
  }
  return (
    <section className={classNames(classes)}>
      <div className="container">
        <div className={styles.typographyContent}>{content}</div>
      </div>
    </section>
  );
};

Typography.defaultProps = defaultProps;
