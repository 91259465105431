import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash/get';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IButtonField } from 'models/button';

import { RootState, useAppDispatch } from 'redux/index';
import { getPageFetch } from 'redux/slices/page';

// eslint-disable-next-line import/named
import { Block, Tabs, Typography, Loader } from 'components';

import { useMobile } from 'utils/hooks';

import { slugs } from 'utils/constants';

import styles from './Solutions.module.scss';

export const Solutions = () => {
  const dispatch = useAppDispatch();
  const { contentModules, isLoading } = useSelector((state: RootState) => state.page);
  const { isMobile } = useMobile(568);

  const navigate = useNavigate();

  const onButtonClick = useCallback((path?: string) => () => navigate(path || ''), [navigate]);

  useEffect(() => {
    dispatch(getPageFetch(slugs.solutions));
  }, []);

  const contentRender = () =>
    contentModules?.map((i: any) => {
      const isFullWidth = i?.fields?.isFullWidth === 'Yes';

      switch (i?.fields?.slug) {
        case slugs.section:
          return (
            <Block
              isBottomAlign={i?.fields?.isBottomAlign === 'yes'}
              linkToSeeMore={i?.fields?.contentModules?.find((el: any) => el?.fields?.slug?.toLowerCase() === 'link')}
              isSmallContent={i?.fields?.isSmallContent === 'Yes'}
              isFullWidth={isFullWidth}
              key={i?.sys?.id}
              content={documentToReactComponents(i?.fields?.content)}
              buttons={i?.fields?.contentModules?.filter((i: IButtonField) => i?.fields?.slug === 'button')}
              backgroundColor={get(i, 'fields.backgroundColor', '')}
              imagePosition={get(i, 'fields.imagePosition', 'left')}
              images={get(i, 'fields.images', [])}
              isMobile={isMobile}
              commonClasses={{
                left: styles.solutionsLeft,
                right: styles.solutionsRight
              }}
              onButtonClick={onButtonClick}
              isLastItem={i === contentModules?.length - 1}
            />
          );
        case slugs.tabs_block:
          return (
            <Tabs
              key={i?.sys?.id}
              isFullWidth={isFullWidth}
              tabs={get(i, 'fields.tabs', [])}
              menu={get(i, 'fields.contentList', [])}
              backgroundImages={get(i, 'fields.backgroundImages', [])}
            />
          );
        case slugs.typography:
          return (
            <Typography
              additionalStyles={{
                solutionsWrapper: styles.solutionsWrapper,
                solutionsContent: styles.solutionsContent
              }}
              key={i?.sys?.id}
              content={documentToReactComponents(i?.fields?.content)}
            />
          );
        default:
          return null;
      }
    });

  if (isLoading) return <Loader />;

  return <main>{contentRender()}</main>;
};
