import { documentToReactComponents, Options, RenderNode } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';
import { useEffect } from 'react';
import styles from './Content.module.scss';

interface ContentProps {
  content: Document;
  title: string;
  getId: (title: string) => string;
  navigate?: any
}
export const Content = ({ content, title, getId, navigate }: ContentProps) => {
  const redirect = (path:string) => (e:any) => {
    e.preventDefault()
    navigate(path)
  }
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: ({ content }: any, children:any) => {
        return (
          content[0].value ? <p id={getId(content[0].value)}>{children}</p> : <br />
        )
      },
      [INLINES.HYPERLINK]: (node:any) => {
        return (
          <a
            onClick={redirect(node.data.uri)}
            href={node.data.uri}
          >
            {node.content[0].value}</a>
        )
      }
    },
  };
  useEffect(() => {
    const listener = () => {
      const listElem = document.querySelector(`.${styles.content__text} ol`);
      if (listElem) {
        const { children } = listElem;

        if (children.length) {
          for (let i = 0; i < children.length; i += 1) {
            const firstParagraph = children[i].querySelector('p');
            const olChild = children[i].querySelector('ol');
            if (firstParagraph && firstParagraph.innerText) {
              firstParagraph.innerText = `${i + 1}. ${firstParagraph.innerText}`;
            }
            if (olChild && olChild.children.length) {
              [...olChild.children].forEach((item, index) => {
                const firstParagr = item.querySelectorAll('p')[0];
                if (firstParagr && firstParagr.innerText) {
                  firstParagr.innerText = `${i + 1}.${index + 1}. ${firstParagr.innerText}`;
                }
              });
            }
          }
        }
      }
    };

    if (document.readyState === 'complete') {
      listener();
    } else {
      window.addEventListener('load', listener);

      return () => window.removeEventListener('load', listener);
    }
    return () => undefined;
  }, []);
  return (
    <section className={styles.content}>
      <h2 className={styles.content__title}>{title}</h2>
      <div className={styles.content__text}>{documentToReactComponents(content, options as Options)}</div>
    </section>
  );
};
