import React from 'react';

import LinkToSeeMore from 'components/linkToSeeMore/LinkToSeeMore';

import { IListItem } from 'models/listItem';

import styles from './ListItem.module.scss';
import classNames from 'classnames';

interface ListItemProps {
  itemContent?: IListItem;
  syncPage?: boolean;
}

const ListItem = ({ itemContent, syncPage }: ListItemProps) => {
  const icon = itemContent?.fields?.images?.find((i) => i?.fields?.title?.toLowerCase()?.includes('icon'));
  return (
    <div className={classNames(styles.itemWrapper, { [styles.center]: syncPage })}>
      <div>
        <img className={styles.itemLogo} src={icon?.fields?.file?.url} alt={icon?.sys?.id} />
      </div>
      <div className={styles.contentText}>
        <h3 className={styles.titleItem}>{itemContent?.fields?.title}</h3>
        <p className={styles.textItem}>{itemContent?.fields?.subtitle}</p>
      </div>
      {!syncPage && <LinkToSeeMore linkItem={itemContent?.fields?.linkToSeeMore} />}
    </div>
  );
};

export default ListItem;
