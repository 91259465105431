import classNames from 'classnames';

import {IListItem} from "models/listItem";

import ListItem from './ListItem/ListItem';

import styles from './HorizontalList.module.scss';

interface HorizontalListProps {
  content: IListItem[];
  syncPage?: boolean;
  title?: any; //long text type
}

const HorizontalList = ({ content, syncPage, title }: HorizontalListProps) => (
  <div className={styles.wrapper}>
    {title && <div className={`container ${styles.content}`}>{title}</div>}
    <div className={classNames(styles.listWrapper, 'container')}>
      {content?.map((current) => (
        <ListItem key={current?.sys?.id} itemContent={current} syncPage={syncPage} />
      ))}
    </div>
    {!syncPage && <hr />}
  </div>
);

export default HorizontalList;
