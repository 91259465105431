import { Link } from 'react-router-dom';

interface LinkProps {
  isLocal: boolean;
  linkUrl?: string;
  children?: JSX.Element | JSX.Element[] | string;
  setShownId?: React.Dispatch<React.SetStateAction<string>> | any;
  navigate?: any;
}

export const LinkWrapper = ({ isLocal, linkUrl, children, setShownId, navigate }: LinkProps) => {
  if (isLocal) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a href={linkUrl} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  return (
    <Link
      onClick={(e) => {
        e.preventDefault();
        navigate(linkUrl);
        if (setShownId) setShownId('');
      }}
      to={linkUrl || '/'}
    >
      {children}
    </Link>
  );
};
