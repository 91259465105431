import { get } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useNavigate } from 'react-router-dom';

import { Block, Loader } from 'components';
import { Richtext } from 'components/richtext/Richtext';
import { TitleSection } from 'components/titleSection/TitleSection';

import { RootState, useAppDispatch } from 'redux/index';
import { getPageFetch } from 'redux/slices/page';

import { slugs } from 'utils/constants';

import { useMobile } from 'utils/hooks';

export const PrivacyPolicy = () => {
  const dispatch = useAppDispatch();
  const { contentModules, isLoading } = useSelector((state: RootState) => state.page);
  const { isMobile } = useMobile(568);
  const navigate = useNavigate();

  const onButtonClick = useCallback((path?: string) => () => navigate(path || ''), [navigate]);

  useEffect(() => {
    dispatch(getPageFetch(slugs.privacyPolicy));
  }, []);

  const contentRender = () =>
    contentModules?.map((i: any) => {
      const isFullWidth = i?.fields?.isFullWidth === 'Yes';
      switch (i?.fields?.slug) {
        case slugs.section:
          return (
            <Block
              isBottomAlign={i?.fields?.isBottomAlign === 'yes'}
              isSmallContent={i?.fields?.isSmallContent === 'Yes'}
              isFullWidth={isFullWidth}
              key={i?.sys?.id}
              content={documentToReactComponents(i?.fields?.content)}
              buttons={i?.fields?.contentModules?.filter((i: any) => i?.fields?.slug === 'button')}
              backgroundColor={get(i, 'fields.backgroundColor', '')}
              imagePosition={get(i, 'fields.imagePosition', 'left')}
              images={get(i, 'fields.images', [])}
              isMobile={isMobile}
              isLastItem={i === contentModules?.length - 1}
              onButtonClick={onButtonClick}
            />
          );
        case slugs.titleSection:
          return (
            <TitleSection title={i?.fields?.title} backgroundImage={i?.fields?.backgroundImage} key={i?.sys?.id} />
          );
        case slugs.richtextContent:
          return (
            <Richtext
              title={i?.fields?.title}
              content={i?.fields?.content}
              tableOfContent={i?.fields?.tableOfContent}
              key={i?.sys?.id}
            />
          );
        default:
          return null;
      }
    });

  if (isLoading) return <Loader />;

  return <main>{contentRender()}</main>;
};
