import { ContentProps } from 'models/richtext';
import styles from './TableOfContent.module.scss';

interface TableOfContentProps {
  content: ContentProps;
  getId: (title: string) => string;
}

export const TableOfContent = ({ content, getId }: TableOfContentProps) => {
  const orderedList = content.content.find((e) => e.nodeType === 'ordered-list')?.content;
  const orderedListTitles =
    orderedList &&
    orderedList.length &&
    orderedList.map((item) => {
      const titleObj = item.content[0];
      const titleValue = titleObj.content[0].value;
      if (titleObj.nodeType === 'paragraph' && titleObj.content[0].nodeType === 'text' && titleValue) {
        const title = getId(titleValue);
        return (
          <li className={styles.tableOfContent__item} key={title}>
            <a className={styles.tableOfContent__link} href={`#${title}`}>
              {`. ${titleValue}`}
            </a>
          </li>
        );
      }
      return '';
    });

  return orderedListTitles && orderedListTitles.length ? (
    <section className={styles.tableOfContent}>
      <h3 className={styles.tableOfContent__title}>table of contents</h3>
      <ol className={styles.tableOfContent__list}>{orderedListTitles}</ol>
    </section>
  ) : null;
};
