import { get } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { Banner, Section, HorizontalList, Loader, Typography } from 'components';

import { getPageFetch } from 'redux/slices/page';
import { RootState, useAppDispatch } from '../../redux';

import { IButtonField } from 'models/button';
import { IImage } from 'models/Image';
import styles from './Home.module.scss';
import { slugs } from 'utils/constants';

export const Home = () => {
  const dispatch = useAppDispatch();
  const { isLoading, contentModules } = useSelector((state: RootState) => state.page);
  const navigate = useNavigate();
  const location = useLocation();
  const { showNewMessages } = useIntercom();

  const onButtonClick = useCallback((path?: string) => () => navigate(path || ''), [navigate]);

  useEffect(() => {
    dispatch(getPageFetch(slugs.home));
  }, []);

  useEffect(() => {
    if (location.pathname === '/remove-account-request') {
      const composedMessage =
        'User Request Delete\nUser ID: –\nName: –\nEmail Address: –\nOrganization ID: –';
      showNewMessages(composedMessage);
    }
  }, [location]);

  if (isLoading) return <Loader />;

  return (
    <main style={{ display: 'flex', flexDirection: 'column' }}>
      {contentModules &&
        contentModules?.map((current: any, i: number) => {
          switch (current?.fields?.slug) {
            case slugs.section:
              return (
                <Section
                  video={current?.fields?.images?.find((val: IImage) =>
                    val?.fields?.title.toLowerCase().includes('video')
                  )}
                  isFullWidth={current?.fields?.isFullWidth === 'Yes'}
                  key={current?.sys?.id}
                  content={documentToReactComponents(current?.fields?.content)}
                  buttons={current?.fields?.contentModules?.filter((i: IButtonField) => i?.fields?.slug === 'button')}
                  images={current?.fields?.images}
                  imagePosition={current?.fields?.imagePosition}
                  isLastItem={i === contentModules?.length - 1}
                  onButtonClick={onButtonClick}
                  sectionName={current?.fields?.entryName}
                  isSmallContent={current?.fields?.isSmallContent === 'Yes'}
                />
              );
            case slugs.list:
              return <HorizontalList key={current?.sys?.id} content={current?.fields?.contentModules} />;
            case slugs.typography:
              return (
                <Typography
                  key={current?.sys?.id}
                  content={documentToReactComponents(current?.fields?.content)}
                  isHome
                />
              );
            case slugs.banner:
              return (
                <Banner
                  typeOfBanner="home"
                  button={current?.fields?.contentModules?.find((i: IButtonField) => i?.fields?.slug === 'button')}
                  key={current?.sys?.id}
                  backgroundColor={get(current, 'fields.backgroundColor', '')}
                  imagePosition={get(current, 'fields.imagePosition', 'left')}
                  images={get(current, 'fields.images', [])}
                  content={documentToReactComponents(current?.fields?.content)}
                  onButtonClick={onButtonClick}
                />
              );
            default:
              return null;
          }
        })}
    </main>
  );
};
