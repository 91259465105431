import { createSlice } from '@reduxjs/toolkit';

interface InitialStateType {
  contentModules: [];
  entryName: string;
  layout: {
    fields: {
      entryName: string;
      footer: {};
      header: {};
    };
  };
  isLoading: boolean;
  slug: string;
  acceptedCookie: boolean;
  cookieLoading: boolean;
  cookie: {

  },
  isOpenModal: boolean
}

const initialState: InitialStateType = {
  contentModules: [],
  entryName: '',
  layout: {
    fields: {
      entryName: '',
      footer: {},
      header: {}
    }
  },
  isLoading: false,
  slug: '',
  acceptedCookie: false,
  cookieLoading: false,
  cookie: {},
  isOpenModal: false,
};
const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    getPageFetch: (state: InitialStateType, action) => {
      state.isLoading = true;
      state.slug = action.payload;
    },
    getPage: (state: InitialStateType, action: any) => {
      const { contentModules, entryName, layout } = action.payload;
      state.contentModules = contentModules;
      state.entryName = entryName;
      state.layout = layout.fields;
      state.isLoading = false;
    },
    acceptCookies: (state: InitialStateType, action: any) => {
      state.acceptedCookie = action.payload
    },
    fetchCookieEntry: (state: InitialStateType, action: any) => {
      state.cookieLoading = true
    },
    getCookieEntry: (state: InitialStateType, action: any) => {
      state.cookieLoading = false
      state.cookie = action.payload
    },
    onOpenModal: (state: InitialStateType) => {
      state.isOpenModal =  !state.isOpenModal
    }
  }
});

export const { getPage, getPageFetch, acceptCookies, fetchCookieEntry, getCookieEntry, onOpenModal } = pageSlice.actions;
export default pageSlice.reducer;
