import { IMenuItem } from 'models/menuItem';
import { useState } from 'react';
import styles from './Nav.module.scss';
import { NavContainer } from './NavContainer/NavContainer';
import { Navlist } from './NavList/NavList';

interface NavProps {
  menu: IMenuItem[];
  isMobile: boolean;
  isOpen: boolean;
  setIsOpen: (x: boolean | ((prev: boolean) => boolean)) => void;
  setShownId: (x: string) => void;
  shownId: string;
  navigate: any
}
export const NavBlock = ({ menu, isMobile, isOpen, setIsOpen, setShownId, shownId, navigate }: NavProps) => {
  if (isMobile) {
    const [mobileOffset, setMobileOffset] = useState(0);
    // const mobileItemHandler = (
    //   id: string,
    //   elem: HTMLLIElement,
    //   className: string,
    //   setHover: (id: string) => void
    // ) => {
    //   const childs = elem.parentElement && [...elem.parentElement.children];

    //   if (childs?.length) {
    //     childs.forEach((child) => child.classList.remove(className));
    //   }
    //   elem.classList.add(className);
    //   setHover(id);
    //   // setMobileOffset((prev) => prev + 100);
    // };
    return (
      <nav className={styles.nav}>
        {/* <NavContainer
          setShownId={setShownId}
          subMenuItem={subMenu}
          isOpen={isOpen}
          mobileOffset={mobileOffset}
          setMobileOffset={setMobileOffset}
        > */}
        <Navlist
          menu={menu}
          isMobile
          mobileOffset={mobileOffset}
          setMobileOffset={setMobileOffset}
          setIsOpen={setIsOpen}
          setShownId={setShownId}
          shownId={shownId}
          navigate={navigate}
        />
        {/* </NavContainer> */}
      </nav>
    );
  }
  return (
    <nav className={styles.nav}>
      <Navlist menu={menu} setShownId={setShownId} shownId={shownId} navigate={navigate} />
    </nav>
  );
};
