import classNames from 'classnames';
import get from 'lodash/get';
import { IImage } from 'models/Image';
import { Link } from 'react-router-dom';
import styles from './Logo.module.scss';

interface ILogo {
  logo: IImage;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
}

export const Logo = ({ logo, setIsOpen, className }: ILogo) => {
  const url = get(logo, 'fields.file.url', '');
  const description = get(logo, 'fields.description', '');

  const handleClick = () => {
    if (setIsOpen) setIsOpen(false);
  };

  return (
    <Link onClick={handleClick} className={classNames(className ?? '', [styles.logo, 'logo'])} to="/">
      <img src={url} alt={description} />
    </Link>
  );
};
