import React, { CSSProperties } from 'react';
import { IButton } from 'models/button';
import classNames from 'classnames';

import styles from './Button.module.scss';

interface ButtonProps extends IButton {
  handler?: () => void;
  // eslint-disable-next-line react/require-default-props
  buttonClass?: CSSProperties | any; //additional css type
  href?: string;
  type?: 'primary' | 'secondary' | 'third';
}

const Button = ({ title, backgroundColor, handler, buttonClass, href, type }: ButtonProps) => {
  const path = href?.includes('http');

  return (
    <div className={styles.buttonWrapper}>
      {path ? (
        <a
          href={href}
          target="_blank"
          style={{ backgroundColor }}
          rel="noopener noreferrer"
          className={classNames(styles.button, buttonClass, {
            [styles.button_primary]: type === 'primary',
            [styles.button_secondary]: type === 'secondary',
            [styles.button_third]: type === 'third'
          })}
        >
          {title}
        </a>
      ) : (
        <button
          style={{ backgroundColor }}
          className={classNames(styles.button, buttonClass, {
            [styles.button_primary]: type === 'primary',
            [styles.button_secondary]: type === 'secondary',
            [styles.button_third]: type === 'third'
          })}
          onClick={handler}
          type="button"
        >
          {title}
        </button>
      )}
    </div>
  );
};

export default Button;
