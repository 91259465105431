import { IImage } from 'models/Image';
import { IGridItem } from 'models/gridItem';
import { buttonCb, IButtonField } from 'models/button';

import { Button } from 'components';

import { getImagePath } from 'utils/helpers';

import styles from './Grid.module.scss';

interface IProps {
  title: any; //rich text type
  images?: IImage[];
  gridItems: IGridItem[];
  isFullWidth: boolean;
  button: IButtonField;
  onNavigate?: buttonCb;
}
export const Grid = ({ gridItems, images, title, isFullWidth, button, onNavigate }: IProps) => {
  const gridItemBg = images?.find((i) => getImagePath(i, '{item bg}'))?.fields?.file?.url;
  const content = (
    <>
      <div className={styles.gridTitle}>{title}</div>
      <div className={styles.gridItems}>
        {gridItems.map((i) => (
          <div key={i.sys.id} className={styles.gridItemsItem}>
            <div
              style={{ backgroundImage: gridItemBg ? `url(${gridItemBg})` : '' }}
              className={styles.gridItemsItemImage}
            >
              <img src={i.fields?.icon?.fields?.file?.url} alt={i.fields?.icon?.fields?.description} />
            </div>
            <h3 className={styles.gridItemsItemTitle}>{i.fields?.title}</h3>
          </div>
        ))}
      </div>
      <Button
        backgroundColor={button?.fields?.backgroundColor}
        title={button?.fields?.title}
        handler={onNavigate && onNavigate(button?.fields?.link)}
        type={button?.fields?.backgroundVariant || 'primary'}
      />
    </>
  );
  return (
    <section className={styles.grid}>{isFullWidth ? content : <div className="container">{content}</div>}</section>
  );
};
