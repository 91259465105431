import classNames from 'classnames';
import { ContentProps } from 'models/richtext';
import { Content } from './content/Content';
import styles from './Richtext.module.scss';
import { TableOfContent } from './tableOfContent/TableOfContent';

interface RichtextProps {
  content: ContentProps;
  title: string;
  tableOfContent: boolean;
  navigate?: any
}

export const Richtext = ({ content, title, tableOfContent, navigate }: RichtextProps) => {

  const getId = (title: string) =>
    title
      .replace(/[^a-zA-Z0-9]/g, '')
      .substring(0, 10)
      .toLocaleLowerCase();
  return (

    <section className={styles.richtext}>
      <div className={classNames([styles.richtext__container, 'container'])}>
        <div className={classNames([styles.richtext__col, styles.richtext__col_left])}>
          <Content content={content} title={title} getId={getId} navigate={navigate}/>
        </div>
        <div className={classNames([styles.richtext__col, styles.richtext__col_right])}>
          {tableOfContent && <TableOfContent content={content} getId={getId} />}
        </div>
      </div>
    </section>
  );
};
