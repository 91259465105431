import { useEffect, useState } from 'react';

export const useMobile = (resolution: number) => {
  const [isMobile, setMobileState] = useState(false);

  useEffect(() => {
    const updateSize = () => {
      if (window.innerWidth <= resolution) setMobileState(true);
      else setMobileState(false);
    };
    updateSize();
    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return { isMobile };
};


export const useScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};
