import { configureStore } from '@reduxjs/toolkit';
import pageReducer from './slices/page';
import { useDispatch } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    page: pageReducer
  },
  middleware: [sagaMiddleware]
});

sagaMiddleware.run(rootSaga);

export default store;
//@ts-ignore
export type RootState = ReturnType<typeof store.getState>;
// export type RootState = ReturnType<typeof rootReducer>
type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
