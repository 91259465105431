import React from 'react';
import SwiperCore, { EffectCoverflow, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { IMenuItem } from 'models/sliderMenu';
import { IImage } from 'models/Image';

import LinkToSeeMore from 'components/linkToSeeMore/LinkToSeeMore';
import styles from './MobileSlider.module.scss';

interface MobileSliderProps {
  menu: IMenuItem[];
  currentMainImage?: IImage;
  title: string;
  slideBackground?: IImage;
}

export default function MobileSlider({ menu, currentMainImage, title, slideBackground }: MobileSliderProps) {
  SwiperCore.use([EffectCoverflow, Pagination]);

  return (
    <>
      <h2 className={styles.title}>{title}</h2>
      <Swiper
        slidesPerView={1.07}
        spaceBetween={0}
        pagination={{
          clickable: true
        }}
        className={styles.mobileSwiper}
      >
        {menu.map((val: IMenuItem) => (
          <SwiperSlide className={styles.slide} key={val.sys.id}>
            <div className={styles.blockUlImageWithContent}>
              <img
                alt={slideBackground?.fields?.description}
                className={styles.slideBackground}
                src={slideBackground?.fields?.file?.url}
              />
              <img
                alt={currentMainImage?.fields?.description}
                className={styles.image}
                src={currentMainImage?.fields?.file?.url}
              />
              <div className={styles.content}>{documentToReactComponents(val?.fields?.content)}</div>
              <div>
                <LinkToSeeMore linkItem={val?.fields?.linkToSeeMore} />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
